export default [
  {
    id: 0,
    title: "What is your current employment status?",
    answers: [
      "I am a current full time federal employee",
      "I am currently on active duty in the military",
      "I am a part time federal employee or national guard/reserve military member",
      "I am retired military or a federal employee",
    ],
  },
  {
    id: 1,
    title: "How old are you",
    answers: ["17-30", "30-45", "45-55", "56+"],
  },
  {
    id: 2,
    title: "How many years until you expect to retire?",
    answers: [
      "20 years or more",
      "10 - 20 years",
      "5 - 10 years",
      "Less than 5 years or currently retired",
    ],
  },
  {
    id: 3,
    title: "How much do you currently have in your savings?",
    answers: [
      "6 months or more of living expenses",
      "3-5 months living expenses",
      "1-2 months living expenses",
      "Less than one month of living expenses",
    ],
  },
  {
    id: 4,
    title: "If the stock market lost 40% you would",
    answers: [
      "See it as an opportunty and invest more if you had the cash available",
      "Continue with my currenty strategy, but not invest any more even if I had cash available",
      "Change my strategy and reduce my exposure to risk",
      "Pull my money out of the stock market",
    ],
  },
  {
    id: 5,
    title: "In the next few years you expect your income to",
    answers: ["Increase", "Stay the same", "Decrease", "Decrease drastically"],
  },
  {
    id: 6,
    title:
      "What do you expect out of your TSP? The word volatility is the rate at which your tsp account increases or decreases over a given period of time.",
    answers: [
      "I expect my portfolio to significantly exceed inflation, even if this means higher levels of volatility",
      "I expect my portfolio to exceed inflation, with reasonable levels of volatility",
      "I exepct my portfolio to keep pace with inflation, with lower levels of volatility",
      "I am more concerned with having as little volatility as possible more than keeping pace with inflation",
    ],
  },
  {
    id: 7,
    title: "I'm most comfortable with a portfolio that",
    answers: [
      "May fluctuate by 20% up or down but averages 10% over the life of the investment",
      "May fluctuate by 15% up or down but averages 8% over the life of the investment",
      "May fluctuate by 10% up or down but averages 4-6% over the life of the investment",
      "I would take less than a 2% total return for my portfolio to fluctuate a minimal amount.",
    ],
  },
  {
    id: 8,
    title: "How would you describe yourself?",
    answers: [
      "I am an optimist. I am confident that things will always work out.",
      "I am objective. Show me the benefits and the drawbacks. I'll make a decision and live with it",
      "I am an anxious person. I usually worry about most things",
      "I am a pessimist. If something can go wrong, it will go wrong.",
    ],
  },
  {
    id: 9,
    title: "When you are reviewing your TSP what will you notice first?",
    answers: [
      "The overall longterm performance of the entire portfolio",
      "The most recent results of the entire portfolio",
      "The individual fund that is doing the best",
      "The individual fund that is doing the worst",
    ],
  },
  {
    id: 10,
    title: "If I was given $10,000 in an inheritance I would",
    answers: [
      "Wait for a drop in the market and invest the money according to my current investment strategy",
      "Invest the money immediately in accordance with my current investment strategy",
      "Invest the money in Low Risk Bonds or Certificates of Deposit",
      "Save the money and not invest any of it.",
    ],
  },
];
